import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import SupportIconImage from "../../images/support-icon.svg";
import ShieldIconImage from "../../images/shield-icon.svg";
import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
import SimpleIconImage from "../../images/simple-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: SupportIconImage,
      title: "Data Research",
      description: "We understand the importance of accurate and up-to-date data for effective marketing campaigns. Our skilled team conducts comprehensive data research, ensuring that you have access to valuable insights and information to fuel your marketing strategies."
    },
    { imageSrc: ShieldIconImage, title: "Data Entry", description: "Data entry plays a vital role in maintaining organized and accessible data. We offer reliable data entry services, ensuring that your information is accurately recorded, organized, and easily retrievable whenever you need it."},
    { imageSrc: CustomizeIconImage, title: "Email Listing", description: "Building a targeted email list is crucial for successful email marketing campaigns. Our experts utilize various resources and techniques to compile a quality email list tailored to your specific target audience, enabling you to reach the right people with your marketing messages." },
    { imageSrc: ReliableIconImage, title: "Marketing Strategy", description: "Developing a comprehensive marketing strategy is essential for any business to thrive in today's competitive landscape. Our experienced team can help you define and implement effective marketing strategies that align with your business goals, target your ideal customers, and maximize your return on investment." },
    { imageSrc: FastIconImage, title: "Email Campaigns", description: "We specialize in crafting effective and engaging email campaigns that drive results. From designing eye-catching email templates to creating compelling content, we ensure your messages stand out in crowded inboxes and generate the desired response from your audience." },
    { imageSrc: SimpleIconImage, title: "Lead Generation", description: "Acquiring high-quality leads is a top priority for businesses looking to expand their customer base. Our lead generation strategies help identify potential customers, enabling you to connect with prospects who are most likely to convert." }
  ];

  return (
    <Container id="services">
      <ThreeColumnContainer>
        <Heading style={{ color: "black" }}>Our Professional <span tw="text-primary-500">Services</span></Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
