import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";

const PrimaryBackgroundContainer = tw(Container)`-mx-8 px-8 bg-primary-900 text-gray-100`;

const HeadingContainer = tw.div``;
const Subheading = tw(SubheadingBase)`text-center text-gray-100 mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto text-center text-gray-300`;

const FaqsContainer = tw.div`mt-10 sm:mt-16 w-full flex-1 lg:flex justify-between items-start max-w-screen-lg mx-auto`;
const FaqsColumn = tw.div`w-full lg:max-w-lg lg:mr-12 last:mr-0`;
const Faq = tw.div`select-none cursor-pointer border-b-2 border-primary-300 hover:border-primary-500 transition-colors duration-300 py-6`;
const Question = tw.div`flex justify-between items-center`;
const QuestionText = tw.div`text-sm sm:text-lg font-semibold tracking-wide`;
const QuestionToggleIcon = styled(motion.span)`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const Answer = tw(motion.div)`hidden text-sm font-normal mt-4 text-gray-300`;

export default ({
  subheading = "",
  heading = "Frequently Asked Questions",
  description = "Got questions? We've got answers! Check out our frequently asked questions below for quick insights into our services, processes, and how we can help you achieve your marketing goals. If you don't find the answer you're looking for, feel free to reach out to our friendly team for further assistance. We're here to provide you with the information and support you need to make informed decisions about your marketing strategies.",
  faqs = [
    {
      question: "What industries do you specialize in ?",
      answer:
        "We cater to a diverse range of industries, including but not limited to technology, e-commerce, healthcare, finance, and hospitality. Our expertise allows us to adapt our marketing strategies to suit the unique requirements of each industry."
    },
    {
      question: "How do you ensure the quality of your data research and email lists ?",
      answer:
        "We have a rigorous process in place to ensure the accuracy and quality of our data research and email lists. Our experienced team utilizes reliable sources, conducts thorough verification, and regularly updates the data to maintain its relevance and effectiveness."
    },
    {
      question: "Can you handle both small and large-scale marketing campaigns ?",
      answer:
        "Absolutely! We have the expertise and resources to handle marketing campaigns of all sizes. Whether you are a small startup or an established enterprise, we can tailor our services to match your specific needs and budget."
    },
    {
      question: "What sets your lead generation services apart ?",
      answer:
        "Our lead generation services are highly targeted and focused on identifying and engaging potential customers who are most likely to convert. We leverage advanced techniques, data analysis, and industry insights to generate high-quality leads that have a higher probability of driving business growth."
    },
    {
      question: "How do you measure the success of your marketing campaigns ? ",
      answer:
        "We believe in data-driven marketing, and therefore, we utilize various metrics and analytics tools to measure the success of our campaigns. These include key performance indicators (KPIs) such as conversion rates, click-through rates, engagement metrics, and ROI analysis, among others."
    },
    {
      question: "Can you help with marketing strategy development ?",
      answer:
        "Absolutely! We work closely with our clients to understand their business goals, target audience, and market dynamics. Based on this understanding, we develop customized marketing strategies that align with your objectives and maximize your chances of success.",
    }
  ]
}) => {
  const faqCol1 = [];
  const faqCol2 = [];
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  faqs.map((faq, index) => {
    const renderedFaq = (
      <Faq key={index} onClick={() => toggleQuestion(index)}>
        <Question>
          <QuestionText>{faq.question}</QuestionText>
          <QuestionToggleIcon
            variants={{
              collapsed: { rotate: 0 },
              open: { rotate: -180 }
            }}
            initial="collapsed"
            animate={activeQuestionIndex === index ? "open" : "collapsed"}
            transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <ChevronDownIcon />
          </QuestionToggleIcon>
        </Question>
        <Answer
          variants={{
            open: { opacity: 1, height: "auto", marginTop: "16px", display: "block" },
            collapsed: { opacity: 0, height: 0, marginTop: "0px", display: "none" }
          }}
          initial="collapsed"
          animate={activeQuestionIndex === index ? "open" : "collapsed"}
          transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          {faq.answer}
        </Answer>
      </Faq>
    );

    if (index % 2 === 0) faqCol1.push(renderedFaq);
    else faqCol2.push(renderedFaq);

    return null;
  });
  return (
    <PrimaryBackgroundContainer id="faq">
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          <Description>{description}</Description>
        </HeadingContainer>
        <FaqsContainer>
          <FaqsColumn>{faqCol1}</FaqsColumn>
          <FaqsColumn>{faqCol2}</FaqsColumn>
        </FaqsContainer>
      </ContentWithPaddingXl>
    </PrimaryBackgroundContainer>
  );
};
