import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Features from "components/features/DashedBorderSixFeatures";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import ThreeColWithSideImageWithPrimaryBackgroundFeatures from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
import TwoColumnPrimaryBackgroundFAQS from "components/faqs/TwoColumnPrimaryBackground.js";
import TwoColContactUsFullForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import FiveColumnDarkFooter from "components/footers/FiveColumnDark.js";
import BackgroundAsImage from "components/hero/BackgroundAsImage.js";

//<ThreeColumnWithProfileImageTestimonial/> <TwoColumnWithImage/>  <ProfileThreeColGrid />

export default () => (
  <AnimationRevealPage>
    <BackgroundAsImage />
    <MainFeature />
    <Features />
    <ThreeColWithSideImageWithPrimaryBackgroundFeatures />
    <TwoColumnPrimaryBackgroundFAQS />
    <TwoColContactUsFullForm />
    <FiveColumnDarkFooter />
  </AnimationRevealPage>
);
