import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";

const Container = tw.div`relative bg-primary-900 -mx-8 px-8 text-gray-100`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center text-gray-300`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-xs`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`bg-gray-100 text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`mt-6`}
  }

  .title {
    ${tw`tracking-wider font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-2 font-normal text-gray-400 leading-snug`}
  }
`;

export default ({
  cards = null,
  heading = "Amazing Features",
  subheading = "",
  description = "We secure, customizable, reliable, fast, and easy marketing experience. We are committed to providing you with exceptional service and helping your business thrive."
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: ShieldIconImage,
      title: "Secure",
      description: "We prioritize the security of your data and information. We implement robust security measures to ensure that your confidential data remains protected throughout our services. Trust and confidentiality are at the core of our operations."
    },
    { imageSrc: SupportIconImage, title: "24/7 Support", description: "We understand that marketing needs can arise at any time. That's why we provide round-the-clock support to address your queries, concerns, and requests. Our dedicated support team is available to assist you, ensuring a smooth and hassle-free experience with our services." },
    { imageSrc: CustomizeIconImage, title: "Customizable", description: "We recognize that every business has unique requirements and objectives. Our services are highly customizable to cater to your specific needs. We work closely with you to understand your goals and tailor our solutions accordingly, providing a personalized approach to meet your marketing challenges effectively."},
    { imageSrc: ReliableIconImage, title: "Reliable", description: "With our years of experience and expertise, we have built a reputation for delivering reliable and consistent marketing services. You can count on us to deliver high-quality results that align with your expectations. We strive for excellence and take pride in the trust our clients place in us." },
    { imageSrc: FastIconImage, title: "Fast", description: "We prioritize efficiency and timely delivery of our services. Our streamlined processes and dedicated team enable us to work quickly and effectively, ensuring that your marketing campaigns are launched without delay, giving you a competitive edge." },
    { imageSrc: SimpleIconImage, title: "Easy", description: "We believe that marketing should be easy and hassle-free for our clients. Our user-friendly approach simplifies the process, allowing you to focus on your core business while we handle the marketing-related tasks. Our seamless workflow and clear communication make it easy for you to collaborate with us and achieve your marketing goals." }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container id="features">
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
